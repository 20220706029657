import React, { useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Button, LoadingState, SectionHeader } from "~/common/components";
import Select from "~/common/components/ui/select/Select";
import TextArea from "~/common/components/ui/Textarea";
import { BUTTON_VARIANTS } from "~/common/constants";
import {
  ADMIT_TO_PRACTICE,
  ADMIT_TO_PRACTICE_VALUES,
  LARGE_TEXT_LENGTH,
  REFER_PATIENT,
  REFER_PATIENT_VALUES,
  TEXT_MAX_LENGTH,
} from "~/providers/constants";
import {
  useConsultationCompleteAndSign,
  useGetCurrentTeamMember,
  useListCarePlans,
  useUpsertConsultationCompletionDetails,
} from "~/providers/hooks/queries";
import { generateCarePlanOptions, hasGeneralAccess } from "~/providers/utils";
import {
  AssessmentAndPlanFormValuesType,
  consultationCompletionDataSchema,
  ConsultationCompletionDataType,
  getConsultationCompletionValues,
} from "~/providers/utils/consultationRequests";
import { ConsultationCompletionDetail } from "~/providers/utils/types/consultationCompletionDetailsTypes";
import { ConsultationInputLayout } from "../ConsultationInputLayout";
import { AssessmentAndPlanConfirmationModal } from "./AssessmentAndPlanConfirmationModal";
import { AssessmentAndPlanRadioField } from "./AssessmentAndPlanRadioField";
import { MentalStatusExamination } from "./MentalStatusExamination";

interface AssessmentAndPlanFormProps {
  patientId: string;
  completedData?: ConsultationCompletionDetail | null;
}

export const AssessmentAndPlanForm = ({
  patientId,
  completedData,
}: AssessmentAndPlanFormProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [saveChangesAutomatically, setSaveChangesAutomatically] =
    useState(true);
  const existingData = useRef<ConsultationCompletionDetail | null>();
  const isDirtyRef = useRef(false);
  existingData.current = completedData;

  const { data: teamMember } = useGetCurrentTeamMember();
  const userPermissions = teamMember?.user_permissions ?? [];
  const toggleConfirmationModal = () =>
    setShowConfirmationModal((prev) => !prev);

  const {
    consultationCompleteAndSignMutation,
    isPending: pendingCompleteAndSign,
  } = useConsultationCompleteAndSign(patientId, toggleConfirmationModal);

  const { upsertConsultationCompletionMutation, isPending: pendingSaveData } =
    useUpsertConsultationCompletionDetails({
      patientId,
      saveChangesAutomatically,
      setSaveChangesAutomatically,
    });

  const userWithGeneralAccess = hasGeneralAccess(userPermissions);
  const { data: carePlans, isLoading: loadingCarePlans } = useListCarePlans({
    withPatients: false,
    enabled: userWithGeneralAccess,
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    resetField,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<ConsultationCompletionDataType>({
    resolver: zodResolver(consultationCompletionDataSchema),
    values: getConsultationCompletionValues(userPermissions, completedData),
  });
  isDirtyRef.current = isDirty;
  const formValues = getValues();

  const onSubmit: SubmitHandler<ConsultationCompletionDataType> = (data) =>
    data.completeAndSign
      ? toggleConfirmationModal()
      : upsertConsultationCompletionMutation({
          existingData: existingData.current,
          data: data.data,
        });

  useEffect(() => {
    return () => {
      const unsavedData = getValues();
      if (unsavedData.completeAndSign || !isDirtyRef.current) return;
      upsertConsultationCompletionMutation({
        existingData: existingData.current,
        data: unsavedData.data,
      });
    };
  }, []);

  const handleCompleteSaveData = (completeAndSign: boolean) => {
    setSaveChangesAutomatically(false);
    setValue("completeAndSign", completeAndSign);
  };

  if (loadingCarePlans) return <LoadingState className="h-screen-calculated" />;

  return (
    <form
      className="flex size-full flex-col gap-6 py-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-4">
        <SectionHeader>Assessment & Plan</SectionHeader>
        <ConsultationInputLayout
          label="Mental status examination"
          subtitle="A set of 9 multiple choice questions"
        >
          <MentalStatusExamination
            getValues={getValues}
            register={register}
            errors={errors}
            control={control}
          />
        </ConsultationInputLayout>
        <ConsultationInputLayout label="Observations and diagnostic considerations">
          <TextArea
            placeholder="Add your observations and diagnostic considerations"
            error={errors.data?.diagnostic_considerations?.message}
            rows={3}
            {...register("data.diagnostic_considerations")}
            charactersCount={watch("data.diagnostic_considerations")?.length}
            id="data.diagnostic_considerations"
            maxCharacters={LARGE_TEXT_LENGTH}
          />
        </ConsultationInputLayout>
        <ConsultationInputLayout label="Treatment plan">
          <TextArea
            placeholder="Add your Treatment plan"
            error={errors.data?.treatment_plan?.message}
            rows={3}
            {...register("data.treatment_plan")}
            maxCharacters={LARGE_TEXT_LENGTH}
            charactersCount={watch("data.treatment_plan")?.length}
          />
        </ConsultationInputLayout>
        <div className="flex w-full gap-2">
          {userWithGeneralAccess && (
            <AssessmentAndPlanRadioField
              label="Admit to practice"
              options={ADMIT_TO_PRACTICE_VALUES}
              register={register("data.admit_to_practice", {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  e.target.value === ADMIT_TO_PRACTICE.do_not_admit &&
                  resetField("data.provider_care_plan_id", undefined),
              })}
              error={errors.data?.admit_to_practice?.message}
            >
              <Select
                control={control}
                id="data.provider_care_plan_id"
                options={generateCarePlanOptions(carePlans ?? [])}
                placeholder="Select plan"
                isDisabled={
                  watch("data.admit_to_practice") !== ADMIT_TO_PRACTICE.admit
                }
                error={errors.data?.provider_care_plan_id?.message}
              />
            </AssessmentAndPlanRadioField>
          )}
          <AssessmentAndPlanRadioField
            label="Refer patient"
            options={REFER_PATIENT_VALUES}
            register={register("data.refer_patient", {
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                e.target.value === REFER_PATIENT.no_referral_needed &&
                resetField("data.refer_note", { defaultValue: null }),
            })}
            error={errors.data?.refer_patient?.message}
          >
            <TextArea
              placeholder="Add a note for the referral"
              {...register("data.refer_note")}
              error={errors.data?.refer_note?.message}
              disabled={
                watch("data.refer_patient") !== REFER_PATIENT.referral_provided
              }
              maxCharacters={TEXT_MAX_LENGTH}
              charactersCount={watch("data.refer_note")?.length}
            />
          </AssessmentAndPlanRadioField>
        </div>
      </div>
      <div className="flex w-fit gap-2 self-end pb-3">
        <Button
          type="submit"
          className={twMerge("w-fit", pendingSaveData && "w-32 justify-center")}
          size="sm"
          variant={BUTTON_VARIANTS.outline_black}
          onClick={() => handleCompleteSaveData(false)}
          isLoading={pendingSaveData}
          disabled={!isDirty}
        >
          Save changes
        </Button>
        <Button
          type="submit"
          className="w-fit"
          size="sm"
          onClick={() => handleCompleteSaveData(true)}
        >
          Complete and sign consultation
        </Button>
      </div>
      <AssessmentAndPlanConfirmationModal
        show={showConfirmationModal}
        onClose={toggleConfirmationModal}
        isPending={pendingCompleteAndSign}
        data={formValues.data as AssessmentAndPlanFormValuesType}
        onSubmit={consultationCompleteAndSignMutation}
      />
    </form>
  );
};
