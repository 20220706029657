import { twMerge } from "tailwind-merge";

import {
  EmptyTableState,
  Table,
  TableHead,
  TableHeader,
} from "~/common/components";
import { Patient } from "~/common/utils";
import { formatConsultationRequestRowData } from "~/providers/utils";
import { ConsultationsRequestsRow } from "./ConsultationsRequestsRow";

interface ConsultationRequestsTableProps {
  emptyDataForSearchCriteria: boolean;
  hasConsultations: boolean;
  consultations?: Patient[];
}

export const ConsultationRequestsTable = ({
  consultations,
  emptyDataForSearchCriteria,
  hasConsultations,
}: ConsultationRequestsTableProps) => (
  <div
    className={twMerge(
      "w-full overflow-hidden rounded-xl border border-gray-200",
      !consultations?.length && "h-table",
    )}
  >
    <Table className="size-full">
      <TableHead>
        <tr className="w-full">
          <TableHeader className="w-1/3">Name</TableHeader>
          <TableHeader className="w-1/3">Status</TableHeader>
          <TableHeader
            className={twMerge(emptyDataForSearchCriteria && "w-1/3")}
          >
            Days in state
          </TableHeader>
          <TableHeader />
        </tr>
      </TableHead>
      <tbody className="bg-white text-sm text-gray-70">
        {emptyDataForSearchCriteria && (
          <EmptyTableState
            colSpan={9}
            message={
              hasConsultations
                ? "No consultations match your search criteria."
                : "There aren't any consultations requests yet."
            }
          />
        )}
        {consultations?.map((consultation) => (
          <ConsultationsRequestsRow
            key={consultation.id}
            {...formatConsultationRequestRowData(consultation)}
          />
        ))}
      </tbody>
    </Table>
  </div>
);
