import { useParams } from "react-router-dom";

import { EmptyState, LoadingState } from "~/common/components";
import {
  AssessmentAndPlanForm,
  ConsultationCompletionDetails,
} from "~/providers/components/patientProfile";
import {
  useGetConsultationCompletionDetails,
  useGetPatient,
  useIsTeamMemberStaff,
} from "~/providers/hooks";
import { isConsultationCompleted, PatientParams } from "~/providers/utils";
import { StaffConsultationInProgress } from "./StaffConsultationInProgress";

export const AssessmentAndPlan = () => {
  const { isStaff } = useIsTeamMemberStaff();
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;
  const { data: patientData } = useGetPatient(patientId);

  const { data: completedData, isLoading } =
    useGetConsultationCompletionDetails(patientId);

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  if (isConsultationCompleted(patientData?.status)) {
    if (!completedData)
      return (
        <EmptyState
          message="No consultation completion details available for this patient."
          messageClassName="lg:w-1/4"
        />
      );
    return <ConsultationCompletionDetails details={completedData} />;
  }

  if (isStaff) {
    return (
      <StaffConsultationInProgress
        providerReference={patientData?.provider.preferred_reference ?? ""}
      />
    );
  }

  return (
    <AssessmentAndPlanForm
      patientId={patientId}
      completedData={completedData}
    />
  );
};
