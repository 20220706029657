import { SectionHeader } from "~/common/components";
import { ADMIT_TO_PRACTICE, REFER_PATIENT } from "~/providers/constants";
import { ConsultationCompletionDetail } from "~/providers/utils/types/consultationCompletionDetailsTypes";
import { CompletedConsultationFieldLayout } from "./CompletedConsultationFieldLayout";
import { CompletedMentalStatusExamination } from "./CompletedMentalStatusExamination";

interface ConsultationCompletionDetailsProps {
  details: ConsultationCompletionDetail;
}

export const ConsultationCompletionDetails = ({
  details,
}: ConsultationCompletionDetailsProps) => {
  const {
    id,
    diagnostic_considerations,
    treatment_plan,
    admit_to_practice,
    refer_patient,
    refer_note,
    provider_care_plan,
  } = details;

  const admitToPracticeText =
    admit_to_practice === ADMIT_TO_PRACTICE.admit
      ? `Patient admitted - ${provider_care_plan?.name}`
      : "Patient not admitted";

  const referPatientText =
    refer_patient === REFER_PATIENT.referral_provided
      ? "Referral provided"
      : "No referral needed";

  return (
    <div className="flex flex-col gap-4 py-6" id={id}>
      <SectionHeader>Assessment & Plan</SectionHeader>
      <CompletedMentalStatusExamination results={Object.entries(details)} />
      <CompletedConsultationFieldLayout
        title="Observations and diagnostic considerations"
        description={diagnostic_considerations}
      />
      <CompletedConsultationFieldLayout
        title="Treatment plan"
        description={treatment_plan}
      />
      <CompletedConsultationFieldLayout
        title="Admit to practice"
        description={admitToPracticeText}
      />
      <CompletedConsultationFieldLayout
        title="Refer patient"
        description={referPatientText}
        extraData={refer_note}
      />
    </div>
  );
};
