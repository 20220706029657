import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import { TableData } from "~/common/components/table/TableData";
import Select from "~/common/components/ui/select/Select";
import { PatientScriptsStatusTypes } from "~/common/types/scriptsTypes";
import { Option } from "~/common/utils";
import { ScriptStatus } from "~/patients/components";
import { OWNER_OPTIONS } from "~/providers/constants";
import { useUpdateScriptRequest } from "~/providers/hooks";

export interface PrescriptionRequestRowDetails {
  id: string;
  patientName: string;
  medication: string;
  createdDate: string;
  owner: string;
  preAuthorized: boolean;
  providerApproval: boolean;
  status: PatientScriptsStatusTypes;
  isLoading?: boolean;
  resolution?: string;
}
interface PrescriptionRequestRowProps extends PrescriptionRequestRowDetails {
  ownersOptions: Option[];
}

export const PrescriptionRequestRow = ({
  id,
  patientName,
  medication,
  createdDate,
  owner,
  preAuthorized,
  providerApproval,
  status,
  ownersOptions,
  isLoading,
  resolution,
}: PrescriptionRequestRowProps) => {
  const [prescriptionRequestOwner, setPrescriptionRequestOwner] =
    useState(owner);

  const [params, setParams] = useSearchParams();

  const queryClient = useQueryClient();

  const handleSeeDetails = () => {
    const newParams = new URLSearchParams(params);
    newParams.set("id", id);
    setParams(newParams);
  };

  const onError = () =>
    toast.error("We shoot trouble updating the prescription request owner.");

  const onSuccess = async () => {
    setPrescriptionRequestOwner(id ?? OWNER_OPTIONS.unassigned);
    await queryClient.invalidateQueries({ queryKey: ["getScriptsList"] });
  };

  const { updateScriptMutation, isPending } = useUpdateScriptRequest(
    onError,
    onSuccess,
  );

  const handleUpdatePrescriptionRequestOwner = (owner_id: string | number) => {
    const owner = owner_id === OWNER_OPTIONS.unassigned ? null : `${owner_id}`;
    updateScriptMutation({
      script_id: id,
      updatedData: {
        owner_id: owner,
        status,
        resolution: resolution ?? "",
      },
    });
  };
  const handleSelectClick = (event?: React.MouseEvent) => {
    event?.stopPropagation();
  };

  const showSkeleton = isLoading ?? isPending;

  return (
    <tr
      className="border-b border-gray-200 last:border-none hover:cursor-pointer hover:bg-gray-50"
      onClick={handleSeeDetails}
    >
      <TableData className="rounded-bl-xl">{patientName}</TableData>
      <TableData className="p-4">
        <p className="line-clamp-2">{medication}</p>
      </TableData>
      <TableData className="p-4">{createdDate}</TableData>
      <TableData className="flex h-full items-center justify-center">
        {showSkeleton ? (
          <p className="w-full px-3.5">
            <Skeleton width={`100%`} height={16} />
          </p>
        ) : (
          <Select
            options={ownersOptions}
            id="owner"
            value={prescriptionRequestOwner}
            buttonClassName="border-none bg-transparent px-3.5 py-0 h-full"
            onChange={handleUpdatePrescriptionRequestOwner}
            containerClassName="h-full"
            onClick={handleSelectClick}
            optionsOnClick={handleSelectClick}
          />
        )}
      </TableData>
      <TableData
        className={twMerge("p-4", preAuthorized && "text-primary-600")}
      >
        {preAuthorized ? "Required" : "-"}
      </TableData>
      <TableData
        className={twMerge("p-4", providerApproval && "text-primary-600")}
      >
        {providerApproval ? "Required" : "-"}
      </TableData>
      <TableData className="rounded-br-xl p-4">
        <ScriptStatus status={status} />
      </TableData>
    </tr>
  );
};
