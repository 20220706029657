import { SideBarContent, SidebarLayout } from "~/common/components";
import { Item } from "~/common/utils/types/sideBarTypes";
import { useGetHomeRoute, useSideBarItems } from "~/providers/hooks";
import { useUiStore } from "~/providers/stores/useUiStore";
import { defaultHomeParams } from "~/providers/utils";
import { AuthenticatedTeamMember } from "./AuthenticatedTeamMember";

const TeamMemberSideBar = () => {
  const { homeRoute } = useGetHomeRoute();
  const { setSideBarOpen, sideBarOpen } = useUiStore();
  const { menuItems, bottomMenuItems } = useSideBarItems();

  const handleItemChange = (item: Item) => item.onClick();

  const handleOnClose = () => setSideBarOpen(false);

  return (
    <SidebarLayout isOpen={sideBarOpen} onClose={handleOnClose}>
      <SideBarContent
        menuItems={menuItems}
        bottomMenuItems={bottomMenuItems}
        handleItemChange={handleItemChange}
        onClose={handleOnClose}
        homePath={{
          pathname: homeRoute,
          search: new URLSearchParams(defaultHomeParams).toString(),
        }}
      >
        <AuthenticatedTeamMember />
      </SideBarContent>
    </SidebarLayout>
  );
};

export default TeamMemberSideBar;
