import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import {
  ArchiveIcon,
  Button,
  ConfirmActionModal,
  TableData,
} from "~/common/components";
import { BUTTON_VARIANTS } from "~/common/constants";
import { daysSince } from "~/common/utils";
import { useArchiveLeadPatient, useIsTeamMemberStaff } from "~/providers/hooks";
import { ROUTES } from "~/providers/router";
import { NotificationPoint } from "../icons";

export interface ConsultationsRequestsRowProps {
  id: string;
  unreviewed: boolean;
  patientName: string;
  date?: string;
  description?: string;
}

export const ConsultationsRequestsRow = ({
  id,
  unreviewed,
  patientName,
  description,
  date,
}: ConsultationsRequestsRowProps) => {
  const [showArchivePatient, setShowArchivePatient] = useState(false);
  const { isStaff } = useIsTeamMemberStaff();

  const toggleShowArchivePatientModal = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    setShowArchivePatient((prev) => !prev);
  };

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onSuccessArchive = async () => {
    toast.success(`${patientName} has been archived successfully!`);
    await queryClient.invalidateQueries({
      queryKey: ["getPatients"],
    });
  };

  const { archivePatientMutation } = useArchiveLeadPatient(onSuccessArchive);

  const handleSeePatient = () => {
    navigate(
      generatePath(ROUTES.consultationOverview, {
        patientId: id,
      }),
    );
  };

  const handleArchivePatient = () => archivePatientMutation(id);

  const daysAgo = daysSince(date);
  const dateSinceLastUpdated =
    daysAgo !== "today" && daysAgo !== "yesterday" ? `${daysAgo} ago` : daysAgo;

  return (
    <tr
      className={twMerge(
        "hover:cursor-pointer hover:bg-gray-50",
        unreviewed && "font-medium",
      )}
      onClick={handleSeePatient}
    >
      <>
        <TableData>
          <div className="flex items-center gap-4 capitalize">
            <NotificationPoint
              className={unreviewed ? "text-red-500" : "text-neutral-200"}
            />
            {patientName}
          </div>
        </TableData>
        <TableData className="first-letter:uppercase">
          {description ?? "-"}
        </TableData>
        <TableData className="first-letter:uppercase">
          {date ? `${dateSinceLastUpdated}` : "-"}
        </TableData>
        {
          <TableData className="flex justify-end px-5">
            {!isStaff && (
              <Button
                className="flex w-fit items-center justify-center rounded-md border border-gray-30 p-2 hover:bg-gray-200"
                variant={BUTTON_VARIANTS.outline_none}
                onClick={toggleShowArchivePatientModal}
              >
                <ArchiveIcon className="size-3" />
              </Button>
            )}
          </TableData>
        }
        <ConfirmActionModal
          title="Archive request"
          description="Are you sure you want to archive this request?"
          show={showArchivePatient}
          onClose={toggleShowArchivePatientModal}
          onConfirm={handleArchivePatient}
        />
      </>
    </tr>
  );
};
