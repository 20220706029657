import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  createConsultationCompletionDetails,
  updateConsultationCompletionDetails,
} from "~/providers/api/consultationDetails";
import { SaveConsultationCompletionFormValuesType } from "~/providers/utils/consultationRequests/schemas";
import {
  UpdateConsultationCompletionDetailsProps,
  UpsertConsultationCompletionMutationProps,
} from "~/providers/utils/types/consultationCompletionDetailsTypes";

interface UseUpsertConsultationDetails {
  patientId: string;
  saveChangesAutomatically: boolean;
  setSaveChangesAutomatically: (value: boolean) => void;
}

export const useUpsertConsultationCompletionDetails = ({
  patientId,
  saveChangesAutomatically,
  setSaveChangesAutomatically,
}: UseUpsertConsultationDetails) => {
  const queryClient = useQueryClient();

  const handleSuccess = async (successMessage: string) => {
    setSaveChangesAutomatically(true);
    await queryClient.invalidateQueries({
      queryKey: ["getConsultationCompletionDetail", patientId],
    });
    !saveChangesAutomatically && toast.success(successMessage);
  };

  const {
    mutate: updateConsultationCompletionMutation,
    isPending: pendingUpdate,
  } = useMutation({
    mutationFn: ({
      consultationDetails,
      consultationDetailsId,
    }: UpdateConsultationCompletionDetailsProps) =>
      updateConsultationCompletionDetails({
        patientId,
        consultationDetails,
        consultationDetailsId,
      }),
    onSuccess: async () =>
      await handleSuccess("Consultation details saved successfully!"),
    onError: () =>
      toast.error(
        "We shoot trouble saving the information, is the issue persists please reload the page!",
      ),
  });

  const {
    mutate: createConsultationCompletionMutation,
    isPending: pendingCreate,
  } = useMutation({
    mutationFn: (
      consultationDetails: SaveConsultationCompletionFormValuesType,
    ) =>
      createConsultationCompletionDetails({
        patientId,
        consultationDetails,
      }),
    onSuccess: async () =>
      await handleSuccess("Consultation details saved successfully!"),
    onError: () =>
      toast.error(
        "We shoot trouble saving the information, is the issue persists please reload the page!",
      ),
  });

  const isPending = pendingUpdate || pendingCreate;

  const upsertConsultationCompletionMutation = ({
    data,
    existingData,
  }: UpsertConsultationCompletionMutationProps) =>
    existingData
      ? updateConsultationCompletionMutation({
          consultationDetails: data,
          consultationDetailsId: existingData.id,
        })
      : createConsultationCompletionMutation(data);

  return { upsertConsultationCompletionMutation, isPending };
};
