import { TEAM_MEMBER_PERMISSIONS } from "~/common/constants";
import { UserPermission } from "~/common/utils";

export const hasConsultationAccess = (userPermissions?: UserPermission[]) =>
  userPermissions?.some(
    (userPermission) =>
      userPermission.permission.code ===
      TEAM_MEMBER_PERMISSIONS.consultation_access,
  );

export const hasConsultationAccessOnly = (userPermissions: UserPermission[]) =>
  userPermissions.every(
    (userPermission) =>
      userPermission.permission.code ===
      TEAM_MEMBER_PERMISSIONS.consultation_access,
  );

export const hasGeneralAccess = (userPermissions?: UserPermission[]) =>
  !!userPermissions?.some(
    (userPermission) =>
      userPermission.permission.code === TEAM_MEMBER_PERMISSIONS.general_access,
  );

export const hasFullAccess = (userPermissions?: UserPermission[]) =>
  Object.values(TEAM_MEMBER_PERMISSIONS)?.every(
    (permission) =>
      userPermissions?.some(
        (userPermission) => permission === userPermission.permission.code,
      ),
  );
