import { useGetCurrentTeamMember } from ".";
import { ROUTES } from "../router";
import { getHomeRouteByPermissions } from "../utils";

export const useGetHomeRoute = () => {
  const { data: teamMember } = useGetCurrentTeamMember();
  return {
    homeRoute: teamMember
      ? getHomeRouteByPermissions(teamMember.user_permissions)
      : ROUTES.home,
  };
};
