import { ConsultationDetails, getPatientBaseUrl } from "../utils";
import {
  AssessmentAndPlanFormValuesType,
  SaveConsultationCompletionFormValuesType,
} from "../utils/consultationRequests/schemas";
import {
  ConsultationCompletionDetail,
  UpsertConsultationDetailsData,
} from "../utils/types/consultationCompletionDetailsTypes";
import { api } from "./axios";

export const createConsultationDetails = async (
  patientId: string,
  price: number,
) => {
  const { data } = await api.post<ConsultationDetails>(
    `${getPatientBaseUrl(patientId)}/consultation-details`,
    { price },
  );

  return data;
};

export const completeAndSignConsultationCompletionDetails = async ({
  patientId,
  consultationDetails,
}: UpsertConsultationDetailsData<AssessmentAndPlanFormValuesType>) => {
  const { data } = await api.post<ConsultationCompletionDetail>(
    `${getPatientBaseUrl(
      patientId,
    )}/consultation-completion-details/complete-and-sign`,
    consultationDetails,
  );

  return data;
};

export const updateConsultationCompletionDetails = async ({
  patientId,
  consultationDetails,
  consultationDetailsId,
}: UpsertConsultationDetailsData<SaveConsultationCompletionFormValuesType>) => {
  const { data } = await api.put<ConsultationCompletionDetail>(
    `${getPatientBaseUrl(
      patientId,
    )}/consultation-completion-details/${consultationDetailsId}`,
    consultationDetails,
  );

  return data;
};

export const createConsultationCompletionDetails = async ({
  patientId,
  consultationDetails,
}: UpsertConsultationDetailsData<SaveConsultationCompletionFormValuesType>) => {
  const { data } = await api.post<ConsultationCompletionDetail>(
    `${getPatientBaseUrl(patientId)}/consultation-completion-details`,
    consultationDetails,
  );

  return data;
};

export const getConsultationCompletionDetail = async (patientId: string) => {
  const { data } = await api.get<ConsultationCompletionDetail | null>(
    `${getPatientBaseUrl(patientId)}/consultation-completion-details/current`,
  );

  return data;
};
