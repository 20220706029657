import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type SettingsLayoutProps = PropsWithChildren & ComponentPropsWithoutRef<"div">;

export const SettingsOutletLayout = ({
  children,
  className,
  ...props
}: SettingsLayoutProps) => (
  <div
    className={twMerge(
      "relative flex size-full flex-col items-center gap-6 text-gray-70 md:gap-8",
      className,
    )}
    {...props}
  >
    {children}
  </div>
);
