import { Avatar } from "~/common/assets";
import { Typography } from "~/common/components";
import { useGetCurrentTeamMember } from "~/providers/hooks";
import { getFullName } from "~/providers/utils";

export const AuthenticatedTeamMember = () => {
  const { data: teamMember } = useGetCurrentTeamMember();

  return (
    <div className="mx-5 flex items-center gap-2 border-t border-neutral-700 py-4">
      <img
        className="size-11 rounded-full border border-white"
        alt="profilePicture"
        src={teamMember?.profile_picture_path ?? Avatar}
      />
      <Typography className="truncate font-semibold text-gray-35">
        {getFullName(teamMember?.name, teamMember?.last_name)}
      </Typography>
    </div>
  );
};
