import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { TeamMember } from "~/common/utils";
import {
  ConsultationLayout,
  Layout,
  PatientProfileLayout,
} from "~/providers/layout";
import {
  ConsultationRequestsScreen,
  ExportConsultationReportScreen,
  Home,
  InternalErrorScreen,
  LeadPatientActivityFeed,
  LeadPatientAssessmentAndPlan,
  LeadPatientConsultationReport,
  LeadPatientInformation,
  LeadPatientOverview,
  LeadPatientRawResponses,
  NotFoundScreen,
  OngoingPatientAssessmentAndPlan,
  OngoingPatientConsultationReport,
  OngoingPatientInformation,
  OngoingPatientOverview,
  OngoingPatientRawResponses,
  PatientActivityFeed,
  PatientMedication,
  PatientOrders,
} from "~/providers/screens";
import {
  CarePlans,
  Payment,
  PrescriptionRequest,
  ProviderDetails,
} from "../components";
import { SettingsLayout } from "../layout/SettingsLayout";
import { PatientSearch } from "../screens/PatientSearch";
import {
  getHomeRouteByPermissions,
  hasConsultationAccess,
  hasGeneralAccess,
} from "../utils";
import { ROUTES } from "./routes";

interface AuthRouterProps {
  teamMember: TeamMember;
  previousLocation?: Location;
}
export const AuthenticatedRouter = ({
  teamMember,
  previousLocation,
}: AuthRouterProps) => {
  const location = useLocation();
  return (
    <Routes location={previousLocation ?? location}>
      <Route
        element={
          <Navigate
            to={getHomeRouteByPermissions(teamMember.user_permissions)}
          />
        }
        path="*"
      />
      <Route path={"*"} element={<NotFoundScreen />} />
      <Route path={ROUTES.internalError} element={<InternalErrorScreen />} />
      {hasConsultationAccess(teamMember.user_permissions) && (
        <Route>
          <Route element={<Layout />}>
            <Route
              element={<ConsultationRequestsScreen />}
              path={ROUTES.consultationRequests}
            />
            <Route element={<PatientProfileLayout />}>
              <Route element={<ConsultationLayout />}>
                <Route
                  path={ROUTES.consultationOverview}
                  element={<LeadPatientOverview />}
                />
                <Route
                  path={ROUTES.consultationResponses}
                  element={<LeadPatientRawResponses />}
                />
                <Route
                  path={ROUTES.consultationComplete}
                  element={<LeadPatientAssessmentAndPlan />}
                />
                <Route
                  path={ROUTES.leadPatientConsultationReport}
                  element={<LeadPatientConsultationReport />}
                />
                <Route
                  path={ROUTES.patientOverview}
                  element={<OngoingPatientOverview />}
                />
                <Route
                  path={ROUTES.patientResponses}
                  element={<OngoingPatientRawResponses />}
                />
                <Route
                  path={ROUTES.patientComplete}
                  element={<OngoingPatientAssessmentAndPlan />}
                />
                <Route
                  path={ROUTES.ongoingPatientConsultationReport}
                  element={<OngoingPatientConsultationReport />}
                />
              </Route>
              <Route
                element={<PatientActivityFeed />}
                path={ROUTES.activePatientActivityFeed}
              />
              <Route
                element={<LeadPatientActivityFeed />}
                path={ROUTES.leadPatientActivityFeed}
              />
              <Route
                element={<OngoingPatientInformation />}
                path={ROUTES.patientInfo}
              />
              <Route
                element={<LeadPatientInformation />}
                path={ROUTES.patientConsultationInfo}
              />
            </Route>
            <Route element={<SettingsLayout />}>
              <Route element={<ProviderDetails />} path={ROUTES.account} />
              <Route element={<Payment />} path={ROUTES.payment} />
            </Route>
            <Route element={<PatientSearch />} path={ROUTES.patientSearch} />
          </Route>
          <Route
            path={ROUTES.exportConsultationReport}
            element={<ExportConsultationReportScreen />}
          />
        </Route>
      )}
      {hasGeneralAccess(teamMember.user_permissions) && (
        <>
          <Route element={<Layout />}>
            <Route element={<Home />} path={ROUTES.home} />
            <Route
              element={<PrescriptionRequest />}
              path={ROUTES.prescriptionRequest}
            />
            <Route element={<PatientProfileLayout />}>
              <Route element={<PatientMedication />} path={ROUTES.medication} />
              <Route element={<PatientOrders />} path={ROUTES.orders} />
            </Route>
            <Route element={<SettingsLayout />}>
              <Route element={<ProviderDetails />} path={ROUTES.account} />
              <Route element={<Payment />} path={ROUTES.payment} />
              <Route element={<CarePlans />} path={ROUTES.carePlans} />
            </Route>
            <Route element={<PatientSearch />} path={ROUTES.patientSearch} />
          </Route>
        </>
      )}
    </Routes>
  );
};
