import { useNavigate } from "react-router-dom";

import { InternalError } from "~/common/assets";
import { DescriptiveErrorLayout } from "~/common/components/layouts/DescriptiveErrorLayout";
import { useGetHomeRoute } from "../hooks";

export const InternalErrorScreen = () => {
  const { homeRoute } = useGetHomeRoute();
  const navigate = useNavigate();
  return (
    <DescriptiveErrorLayout
      errorType="500"
      title="Internal Server Error"
      description="Sorry, there were some technical issues while processing your request."
      handleBackToHome={() => navigate(homeRoute)}
    >
      <img src={InternalError} alt="error 500" />
    </DescriptiveErrorLayout>
  );
};
