import { Note } from "~/common/components/ui";
import { useGetCurrentTeamMember } from "~/providers/hooks";
import { useUpdateNoteMutation } from "~/providers/hooks/queries/patientInformation/useUpdateNoteMutation";
import { hasGeneralAccess } from "~/providers/utils";
import { getPatientInfoCards } from "~/providers/utils/GetPatientInfoCards";
import { PatientData } from "~/providers/utils/types";
import { CurrentCarePlan } from "./CurrentCarePlan";
import InfoCard from "./InfoCard";

interface PatientDetailsProps {
  patient: PatientData;
}

export const PatientDetails = ({ patient }: PatientDetailsProps) => {
  const infoCards = getPatientInfoCards(patient);
  const { data: teamMember } = useGetCurrentTeamMember();
  const providerHasGeneralAccess = hasGeneralAccess(
    teamMember?.user_permissions,
  );
  const { updateNoteMutation } = useUpdateNoteMutation(patient.id);

  const updateNote = async (note: string | null) => {
    await updateNoteMutation({ content: note });
  };

  return (
    <div className="flex w-full flex-col gap-4 px-14 py-8">
      <Note content={patient.note?.content} handleSubmitNote={updateNote} />
      {providerHasGeneralAccess && <CurrentCarePlan />}
      {infoCards.map((card) => (
        <InfoCard {...card} key={card.title} />
      ))}
    </div>
  );
};
