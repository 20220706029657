import { CardInputsContainer } from "~/common/components";
import { useGetPaymentSettings } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/useGetPaymentSettings";
import { SettingsOutletLayout } from "../SettingsOutletLayout";
import { ProviderSettingsForm } from "./ProviderSettingsForm";

export const Payment = () => {
  const { data: providerSettings, isLoading } = useGetPaymentSettings();

  return (
    <SettingsOutletLayout>
      <CardInputsContainer title="Intake Consultation fee / co-pay">
        <ProviderSettingsForm
          providerSettings={providerSettings}
          isLoadingProviderSettings={isLoading}
        />
      </CardInputsContainer>
    </SettingsOutletLayout>
  );
};
