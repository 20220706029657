import { useMutation, useQueryClient } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { completeAndSignConsultationCompletionDetails } from "~/providers/api/consultationDetails";
import { ADMIT_TO_PRACTICE } from "~/providers/constants";
import { ROUTES } from "~/providers/router";
import { AssessmentAndPlanFormValuesType } from "~/providers/utils/consultationRequests/schemas";

export const useConsultationCompleteAndSign = (
  patientId: string,
  onSuccess: () => void,
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: consultationCompleteAndSignMutation, isPending } =
    useMutation({
      mutationFn: (consultationDetails: AssessmentAndPlanFormValuesType) =>
        completeAndSignConsultationCompletionDetails({
          patientId,
          consultationDetails,
        }),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ["getPatient", patientId],
        });
        await queryClient.invalidateQueries({
          queryKey: ["getConsultationCompletionDetail", patientId],
        });
        toast.success("Consultation completed and signed successfully!");
        const path =
          data.admit_to_practice === ADMIT_TO_PRACTICE.admit
            ? ROUTES.patientComplete
            : ROUTES.consultationComplete;
        navigate(generatePath(path, { patientId }));
        onSuccess();
      },
      onError: () =>
        toast.error(
          "We shoot trouble completing the information, is the issue persists please reload the page!",
        ),
    });

  return { consultationCompleteAndSignMutation, isPending };
};
