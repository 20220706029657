import { UseFormSetValue } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Button } from "~/common/components";
import { BUTTON_VARIANTS } from "~/common/constants";
import { ConsultationReportSchemaType } from "~/providers/utils";

interface CompleteSaveButtonsProps {
  isFormDisabled: boolean;
  pendingUpdateData: boolean;
  isDirty: boolean;
  setValue: UseFormSetValue<ConsultationReportSchemaType>;
}

export const CompleteSaveButtons = ({
  isFormDisabled,
  pendingUpdateData,
  isDirty,
  setValue,
}: CompleteSaveButtonsProps) => {
  return (
    <div className="flex w-fit gap-2 self-end pb-3">
      <Button
        type="submit"
        className={twMerge("w-fit", pendingUpdateData && "w-32 justify-center")}
        size="sm"
        variant={BUTTON_VARIANTS.outline_black}
        onClick={() => {
          setValue("saveData", true);
        }}
        isLoading={pendingUpdateData}
        disabled={isFormDisabled || !isDirty}
      >
        Save changes
      </Button>
      <Button
        type="submit"
        className="w-fit"
        size="sm"
        disabled={isFormDisabled || pendingUpdateData}
        onClick={() => setValue("saveData", false)}
      >
        Complete consultation report
      </Button>
    </div>
  );
};
