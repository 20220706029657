import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { CustomError } from "~/common/types/errorTypes";
import { createConsultationDetails } from "~/providers/api/consultationDetails";

export const useCreateConsultationDetails = (onCloseModal: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: createConsultationDetailsMutation, isPending } = useMutation({
    mutationFn: ({ patientId, price }: { patientId: string; price: number }) =>
      createConsultationDetails(patientId, price),
    onError: (error: CustomError) =>
      toast.error(
        error.response?.data.detail ??
          "There was an issue creating the consultation details. Please try again later.",
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatient"],
      });
      toast.success("Consultation details created successfully!");
      onCloseModal();
    },
  });

  return { createConsultationDetailsMutation, isPending };
};
