import type { Location } from "react-router-dom";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { ForgotPassword, Login, ResetPassword } from "~/providers/screens";
import { ROUTES } from "./routes";

export const GuestRouter = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  return (
    <Routes location={previousLocation ?? location}>
      <Route element={<Navigate to={ROUTES.login} replace />} path={"*"} />
      <Route element={<Login />} path={ROUTES.login} />
      <Route element={<ForgotPassword />} path={ROUTES.forgotPassword} />
      <Route element={<ResetPassword />} path={ROUTES.resetPassword} />
    </Routes>
  );
};
