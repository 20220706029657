import { useNavigate } from "react-router-dom";

import { NotFound } from "~/common/assets";
import { DescriptiveErrorLayout } from "~/common/components/layouts/DescriptiveErrorLayout";
import { useGetHomeRoute } from "../hooks";

export const NotFoundScreen = () => {
  const { homeRoute } = useGetHomeRoute();
  const navigate = useNavigate();
  return (
    <DescriptiveErrorLayout
      errorType="404"
      title="Page not found"
      description="Sorry, the page you are looking for does not exist."
      handleBackToHome={() => navigate(homeRoute)}
    >
      <img src={NotFound} alt="error 404" />
    </DescriptiveErrorLayout>
  );
};
