import { PropsWithChildren } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { ErrorText } from "~/common/components";
import { replaceUnderscores } from "~/common/utils";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import { ConsultationInputLayout } from "../ConsultationInputLayout";

interface AssessmentAndPlanRadioFieldProps extends PropsWithChildren {
  label: string;
  options: readonly string[];
  register: UseFormRegisterReturn;
  error?: string;
}

export const AssessmentAndPlanRadioField = ({
  label,
  options,
  children,
  register,
  error,
}: AssessmentAndPlanRadioFieldProps) => (
  <ConsultationInputLayout label={label}>
    <div className="flex w-full flex-col gap-2.5">
      <div className={twMerge("flex flex-col", error && "gap-2")}>
        <div className="flex w-full gap-3">
          {options.map((value) => (
            <RadioButtonOption
              key={value}
              label={replaceUnderscores(value)}
              variant="simple"
              containerClassName="w-auto"
              value={value}
              {...register}
            />
          ))}
        </div>
        {error && <ErrorText>{error}</ErrorText>}
      </div>
      {children}
    </div>
  </ConsultationInputLayout>
);
