import { Table } from "~/common/components/table";
import { PatientMedicationDetail } from "~/common/types";
import { Styled } from "~/common/utils";
import { formatDate, getMedicationDetailsProps } from "~/providers/utils";
import { CheckDetails, DetailsRow } from "./detailsDrawer";

interface MedicationDetailsProps extends Styled {
  medicationDetail?: PatientMedicationDetail;
  medicationName?: string;
}

export const MedicationDetails = ({
  medicationDetail,
  medicationName,
  className,
}: MedicationDetailsProps) => {
  const prescriptionOrderText = medicationDetail?.submit_prescription_order
    ? "Submit prescription order for a medication"
    : "Do not submit a prescription order";

  if (!medicationDetail) return null;

  return (
    <div className="flex h-full flex-col gap-2 pt-4">
      <h2 className="text-lg font-medium leading-6 text-gray-70 first-letter:uppercase">
        {medicationName}
      </h2>
      <div className="flex h-full flex-col gap-3 divide-y">
        <Table>
          <tbody>
            {getMedicationDetailsProps(medicationDetail).map((detail) => (
              <DetailsRow
                {...detail}
                key={detail.label}
                className={className}
              />
            ))}
          </tbody>
        </Table>
        <div className="flex h-full w-full flex-col items-end justify-between pt-3">
          <div className="flex w-full flex-col gap-2">
            {medicationDetail.start_medication_change_order && (
              <CheckDetails> Start ‘Medication change’ orders</CheckDetails>
            )}
            <CheckDetails>
              <div className="flex flex-col">
                <p>{prescriptionOrderText}</p>
                <p className="italic text-gray-35">
                  {medicationDetail.submit_prescription_order_reason}
                </p>
              </div>
            </CheckDetails>
          </div>

          {medicationDetail.created_at && (
            <p className="text-gray-35">
              Order date {formatDate(medicationDetail.created_at)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
