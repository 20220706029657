import { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { SectionMenu } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { AfterScreeningButtons, ReferPatientModal } from "../components";
import { ProceedToIntakeFormModal } from "../components/consultationRequests/postScreening";
import { ConsultationHeader } from "../components/patientProfile/consultation/ConsultationHeader";
import {
  PROCEED_TO_INTAKE_ACTION,
  REFER_ACTION,
  ScreeningSubmittedConsultationsActions,
} from "../constants";
import { useGetPatient, useIsTeamMemberStaff } from "../hooks";
import { getConsultationMenuOptions } from "../utils/getConsultationMenuOptions";

export const ConsultationLayout = () => {
  const navigate = useNavigate();
  const { isStaff } = useIsTeamMemberStaff();

  const { patientId } = useParams();

  const { data: patient } = useGetPatient(patientId);

  const [patientNextAction, setPatientNextAction] =
    useState<ScreeningSubmittedConsultationsActions | null>();

  if (!patientId || !patient) return null;

  const patientStatus = patient.status;
  const showOnScreeningSubmittedButtons =
    patientStatus === PATIENT_STATUS.screening_submitted && !isStaff;

  const menuOptions = getConsultationMenuOptions(
    navigate,
    patientId,
    patientStatus,
  );

  const toggleModal = (modalName?: ScreeningSubmittedConsultationsActions) => {
    setPatientNextAction(modalName);
  };

  return (
    <>
      <div className="flex h-full w-full flex-col px-14 pt-5 text-sm">
        <ConsultationHeader
          dateStarted={patient.email_verified_at}
          patientStatus={patientStatus}
        />
        <div className="flex gap-2 pt-6">
          <SectionMenu menuOptions={menuOptions} />
          {showOnScreeningSubmittedButtons && (
            <AfterScreeningButtons toggleModal={toggleModal} />
          )}
        </div>

        <div className="h-full overflow-y-auto">
          <Outlet />
        </div>
      </div>

      {showOnScreeningSubmittedButtons && (
        <>
          <ReferPatientModal
            handleCloseModal={toggleModal}
            patientId={patientId}
            isOpenModal={patientNextAction === REFER_ACTION}
          />

          <ProceedToIntakeFormModal
            isOpenModal={patientNextAction === PROCEED_TO_INTAKE_ACTION}
            handleCloseModal={toggleModal}
            patientId={patientId}
          />
        </>
      )}
    </>
  );
};
