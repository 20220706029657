import { useState } from "react";

import { Button, SectionHeader, Typography } from "~/common/components";
import { BUTTON_SIZES } from "~/common/constants";
import { useIsTeamMemberStaff } from "~/providers/hooks";
import { ChangePlanModal } from "../carePlans";

export const CurrentCarePlanEmptyState = () => {
  const [upsertPlanModal, setUpsertPlanModal] = useState(false);
  const { isStaff } = useIsTeamMemberStaff();

  const toggleUpsertPlanModal = () => setUpsertPlanModal((prev) => !prev);

  return (
    <div className="flex flex-col gap-6 border-y py-6">
      <div className="flex justify-between">
        <SectionHeader>Current plan</SectionHeader>
        {!isStaff && (
          <Button
            size={BUTTON_SIZES.sm}
            className="w-fit"
            onClick={toggleUpsertPlanModal}
          >
            Add plan
          </Button>
        )}
      </div>
      <div className="flex flex-col gap-4 rounded-lg border border-primary-200 bg-primary-50 p-4 text-gray-70">
        <Typography className="text-lg font-semibold capitalize">
          No plan selected
        </Typography>
      </div>
      {!isStaff && (
        <ChangePlanModal
          show={upsertPlanModal}
          onClose={toggleUpsertPlanModal}
        />
      )}
    </div>
  );
};
