import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import Input from "~/common/components/ui/Input";
import TextArea from "~/common/components/ui/Textarea";
import CheckBox from "~/patients/components/ui/CheckBox";
import { TEXT_MAX_LENGTH } from "~/providers/constants";
import { useCreateCarePlan, useUpdateCarePlan } from "~/providers/hooks";
import {
  baseCarePlan,
  upsertCarePlan,
} from "~/providers/utils/paymentAndCarePlansSchemas";
import { CarePlanWithPatients } from "~/providers/utils/types/carePlanTypes";

export type UpsertCarePlanFormValues = z.infer<typeof upsertCarePlan>;

interface UpsertCarePlanProps {
  show: boolean;
  onClose: () => void;
  carePlan?: CarePlanWithPatients;
}

export const UpsertCarePlanModal = ({
  carePlan,
  show,
  onClose,
}: UpsertCarePlanProps) => {
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    watch,
    reset,
  } = useForm<UpsertCarePlanFormValues>({
    resolver: zodResolver(upsertCarePlan),
    values: carePlan ?? baseCarePlan,
  });

  const handleCloseModal = () => {
    onClose();
    setTimeout(() => reset(), 300);
  };

  const { createCarePlanMutation, isPending: isCreatePending } =
    useCreateCarePlan(handleCloseModal);

  const { updateCarePlanMutation, isPending: isUpdatePending } =
    useUpdateCarePlan(handleCloseModal);

  const onSubmit: SubmitHandler<UpsertCarePlanFormValues> = (data) => {
    carePlan
      ? updateCarePlanMutation({ ...data, id: carePlan.id })
      : createCarePlanMutation(data);
  };

  return (
    <Modal
      title={carePlan ? "Modify care plan" : "Add new care plan"}
      show={show}
      onClose={handleCloseModal}
      className="min-w-[38.75rem]"
    >
      <form
        className="flex w-full flex-col items-end gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex w-full flex-col gap-3">
          <Input
            id="name"
            disabled={!!carePlan}
            label="Care Plan name"
            placeholder="Enter Care Plan name"
            {...register("name")}
            error={errors.name?.message}
          />
          <TextArea
            rows={3}
            id="description"
            label="Description (internal only)"
            placeholder="Enter description"
            {...register("description")}
            error={errors?.description?.message}
            charactersCount={watch("description")?.length}
            maxCharacters={TEXT_MAX_LENGTH}
            className="border border-gray-20 placeholder:not-italic"
          />
          <Input
            id="price"
            label="Monthly fee"
            placeholder="Enter monthly fee"
            {...register("price", {
              setValueAs: (priceValue) => `${priceValue}`,
            })}
            error={errors.price?.message}
          />
        </div>
        <div
          className={twMerge(
            "flex w-full flex-row items-center justify-between",
            carePlan && "justify-end",
          )}
        >
          {!carePlan && (
            <CheckBox id="default" {...register("default")}>
              Set as default Care Plan
            </CheckBox>
          )}
          <CancelSubmitButtons
            disableSubmit={!isDirty}
            handleCancel={handleCloseModal}
            variant="right-outline-black"
            submitButtonLabel="Save"
            containerClassName="min-w-60"
            isSubmitting={isCreatePending || isUpdatePending}
          />
        </div>
      </form>
    </Modal>
  );
};
