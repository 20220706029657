import { useLocation } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { useGetCurrentTeamMember, useTeamMemberIsAuth } from "../hooks";
import { AuthenticatedRouter } from "./AuthenticatedRouter";
import { GuestRouter } from "./GuestRouter";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  const { data: isLoggedIn, isFetching } = useTeamMemberIsAuth();
  const { data: teamMember, isFetching: fetchingCurrentUser } =
    useGetCurrentTeamMember();

  if (isFetching || fetchingCurrentUser) {
    return <LoadingState className="h-screen-calculated" />;
  }

  if (!isLoggedIn) {
    return <GuestRouter />;
  }

  if (isLoggedIn && teamMember) {
    return (
      <AuthenticatedRouter
        teamMember={teamMember}
        previousLocation={previousLocation}
      />
    );
  }
};
