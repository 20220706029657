import { matchPath, useNavigate } from "react-router-dom";

import { useGetCurrentTeamMember, useIsTeamMemberStaff } from ".";
import { ROUTES } from "../router";
import { hasGeneralAccess } from "../utils";

interface ProviderSettingsMenuOptions {
  label: string;
  onClick: () => void;
  enabled: boolean;
  current?: boolean;
}

export const useGetSettingsLayoutMenuOptions = () => {
  const navigate = useNavigate();
  const { isStaff } = useIsTeamMemberStaff();
  const { data: teamMember } = useGetCurrentTeamMember();
  const userPermissions = teamMember?.user_permissions ?? [];

  const actualPath = window.location.pathname;

  const menuOptions: ProviderSettingsMenuOptions[] = [
    {
      label: "Account",
      onClick: () => {
        navigate(ROUTES.account);
      },
      current: !!matchPath(ROUTES.account, actualPath),
      enabled: true,
    },
    {
      label: "Payments",
      onClick: () => {
        navigate(ROUTES.payment);
      },
      current: !!matchPath(ROUTES.payment, actualPath),
      enabled: !isStaff,
    },
    {
      label: "Care Plans",
      onClick: () => {
        navigate(ROUTES.carePlans);
      },
      current: !!matchPath(ROUTES.carePlans, actualPath),
      enabled: !isStaff && hasGeneralAccess(userPermissions),
    },
  ];

  return { menuOptions };
};
