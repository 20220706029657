import { UserPermission } from "~/common/utils";
import { ROUTES } from "../../router";
import { hasConsultationAccessOnly } from "./checkUserPermissions";

export const getHomeRouteByPermissions = (permissions: UserPermission[]) => {
  let homeRoute: string = ROUTES.home;
  if (hasConsultationAccessOnly(permissions)) {
    homeRoute = ROUTES.consultationRequests;
  }
  return homeRoute;
};
