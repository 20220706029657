import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { CancelSubmitButtons, LoadingState } from "~/common/components";
import Modal from "~/common/components/Modal";
import Select from "~/common/components/ui/select/Select";
import { useListCarePlans } from "~/providers/hooks";
import { useCreatePatientCarePlan } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/myCarePlans/useCreatePatientCarePlan";
import { generateCarePlanOptions, PatientParams } from "~/providers/utils";
import {
  carePlanOptionsSchema,
  CarePlanOptionsSchemaValues,
} from "~/providers/utils/carePlans/carePlansSchemas";
import { ChangePlanModalDescription } from "./ChangePlanModalDescription";

interface ChangePlanModalProps {
  show: boolean;
  onClose: () => void;
  plan?: string;
  dateStarted?: string;
  currentCarePlanId?: string;
}

export const ChangePlanModal = ({
  onClose,
  show,
  dateStarted,
  plan,
  currentCarePlanId,
}: ChangePlanModalProps) => {
  const { data: carePlansList, isLoading } = useListCarePlans({
    withPatients: false,
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<CarePlanOptionsSchemaValues>({
    resolver: zodResolver(carePlanOptionsSchema),
    values: {
      carePlanId: currentCarePlanId ?? "",
    },
  });

  const carePlanId = watch("carePlanId");

  const handleClose = () => {
    reset({ carePlanId: currentCarePlanId });
    onClose();
  };

  const { createPatientCarePlanMutation, isPending } =
    useCreatePatientCarePlan(handleClose);

  const { patientId } = useParams<keyof PatientParams>() as PatientParams;

  if (!carePlansList?.length) return null;

  const onSubmit = ({ carePlanId }: CarePlanOptionsSchemaValues) =>
    createPatientCarePlanMutation({
      patientId,
      carePlanId,
    });

  const carePlansListOptions = generateCarePlanOptions(carePlansList);

  return (
    <Modal
      className="h-fit w-96"
      title={plan ? "Change plan" : "Add plan"}
      description={
        dateStarted &&
        plan && (
          <ChangePlanModalDescription dateStarted={dateStarted} plan={plan} />
        )
      }
      show={show}
      onClose={handleClose}
    >
      {isLoading ? (
        <LoadingState />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-end gap-4"
        >
          <div className="flex w-full flex-col gap-4">
            <Select
              id="carePlanId"
              error={errors.carePlanId?.message}
              placeholder="Select plan"
              label="Care plan"
              control={control}
              options={carePlansListOptions}
            />
          </div>

          <div className="w-1/2">
            <CancelSubmitButtons
              handleCancel={handleClose}
              isSubmitting={isPending}
              disableSubmit={!carePlanId || !isDirty}
            />
          </div>
        </form>
      )}
    </Modal>
  );
};
