import { generatePath, matchPath, NavigateFunction } from "react-router-dom";

import { StarsIcon } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { SectionMenuOption } from "~/common/types";
import { PatientStatus } from "~/common/utils";
import { ROUTES } from "../router";
import { getAssessmentAndPlanRoute } from "./getUrls";
import { patientCompletedIntake } from "./patientStatusHistory";

export const getConsultationMenuOptions = (
  navigate: NavigateFunction,
  patientId: string,
  patientStatus: PatientStatus,
): SectionMenuOption[] => {
  const actualPath = window.location.pathname;
  const isPatientActive = patientStatus === PATIENT_STATUS.ongoing_care;

  const showConsultationCompletionTabs =
    patientCompletedIntake(patientStatus) || isPatientActive;

  const overviewRoute = isPatientActive
    ? ROUTES.patientOverview
    : ROUTES.consultationOverview;

  const rawResponsesRoute = isPatientActive
    ? ROUTES.patientResponses
    : ROUTES.consultationResponses;

  const completeRoute = getAssessmentAndPlanRoute(patientStatus);
  const consultationReportRoute = isPatientActive
    ? ROUTES.ongoingPatientConsultationReport
    : ROUTES.leadPatientConsultationReport;
  return [
    {
      label: "Preview",
      onClick: () =>
        navigate(
          generatePath(overviewRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(overviewRoute, actualPath),
      enable: true,
      icon: <StarsIcon />,
    },
    {
      label: "Raw responses",
      onClick: () =>
        navigate(
          generatePath(rawResponsesRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(rawResponsesRoute, actualPath),
      enable: true,
    },
    {
      label: "Assessment & plan",
      onClick: () =>
        navigate(
          generatePath(completeRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(completeRoute, actualPath),
      enable: showConsultationCompletionTabs,
    },
    {
      label: "Consultation report (H&P)",
      onClick: () =>
        navigate(
          generatePath(consultationReportRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(consultationReportRoute, actualPath),
      enable: showConsultationCompletionTabs,
    },
  ];
};
