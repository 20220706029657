import { useParams } from "react-router-dom";

import { EmptyState, LoadingState } from "~/common/components";
import { differenceInDaysFromToday } from "~/common/utils";
import {
  useGetConsultationCompletionDetails,
  useGetConsultationReport,
  useGetPatient,
  useIsTeamMemberStaff,
} from "~/providers/hooks";
import { isConsultationCompleted, PatientParams } from "~/providers/utils";
import { ConsultationReportForm } from "./ConsultationReportForm";
import { SubmittedConsultationReport } from "./SubmittedConsultationReport";

export const ConsultationReport = () => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;
  const { data: patientData } = useGetPatient(patientId);
  const { isStaff } = useIsTeamMemberStaff();

  const { data: completedReportData, isLoading } = useGetConsultationReport(
    patientId,
    isConsultationCompleted(patientData?.status),
  );
  const { data: completedDataConsultationData } =
    useGetConsultationCompletionDetails(patientId, !completedReportData);
  if (!patientData) {
    return null;
  }

  const isPatientConsultationCompleteWithoutData =
    !completedDataConsultationData &&
    isConsultationCompleted(patientData.status);

  const isActivePatientWithOldConsultationData =
    completedDataConsultationData?.consultation.end_date &&
    differenceInDaysFromToday(
      completedDataConsultationData.consultation.end_date,
    ) > 1;

  const noConsultationReportAvailable =
    isPatientConsultationCompleteWithoutData ||
    isActivePatientWithOldConsultationData;

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  if (!!completedReportData?.completed_at && completedReportData) {
    return <SubmittedConsultationReport data={completedReportData} />;
  }

  if (isStaff || noConsultationReportAvailable) {
    return (
      <EmptyState
        message="No consultation report (H&P) available for this patient."
        messageClassName="lg:w-1/4"
      />
    );
  }

  return (
    <ConsultationReportForm
      patientStatus={patientData?.status}
      data={completedReportData}
    />
  );
};
